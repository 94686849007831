import { AdminNavbar } from "./AdminDashboard/AdminNavbar";
import { useCurrentDashboard } from "./Hooks/useCurrentDashboard";
import { MECNavbar } from "./MECDashboard/MECNavBar";
import { MPNNavbar } from "./MPNDashboard/MPNNavbar";
import { ServicesNavbar } from "./ServicesDashboard/ServicesNavbar";

export function Navbar() {
  const { dashboard } = useCurrentDashboard();

  switch (dashboard) {
    case "services":
      return <ServicesNavbar />;
    case "mpn":
      return <MPNNavbar />;
    case "mec":
      return <MECNavbar />;
    case "admin":
      return <AdminNavbar />;
    default:
      return <></>;
  }
}
