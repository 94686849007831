import { useLocation } from "react-router-dom";

export type Dashboard = "services" | "mpn" | "admin" | "mec" | "home";

interface CurrentDashboardState {
  isServices: boolean;
  isMPN: boolean;
  isAdminDashboard: boolean;
  isMEC: boolean;
  dashboard: Dashboard;
}

export function useCurrentDashboard(): CurrentDashboardState {
  const { pathname } = useLocation();

  const route = pathname.split("/")[3];

  const isLanding = route === "home";
  const isServices = route === "services";
  const isMPN = route === "mpn";
  const isMEC = route === "mec";
  const isAdminDashboard = pathname.split("/").includes("admin");

  return {
    isServices,
    isMPN,
    isMEC,
    isAdminDashboard,
    dashboard: isServices
      ? "services"
      : isMPN
      ? "mpn"
      : isAdminDashboard
      ? "admin"
      : isMEC
      ? "mec"
      : isLanding
      ? "home"
      : null
  };
}
