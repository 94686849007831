import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { Clickable } from "./Buttons/Clickable";

const ToastWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const ToastTitle = styled.h4`
  margin: 4px 0;
`;
const ToastMsg = styled.p`
  margin: 4px 0;
`;

type ToastProps = {
  title: string;
  msg?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export function ToastNotification({ title, msg, onClick, children }: ToastProps) {
  return (
    <Clickable onClick={onClick}>
      <ToastWrapper>
        <ToastTitle>{title}</ToastTitle>
        <ToastMsg>{msg && msg}</ToastMsg>
        {children}
      </ToastWrapper>
    </Clickable>
  );
}

export function ToastLongError() {
  const orgCtx = useOrgCtx();
  return (
    <>
      <ToastMsg>{longErrorMsg}</ToastMsg>
      <NavLink to={`/app/${orgCtx}/error-review`}>{longErrorLink}</NavLink>
    </>
  );
}

export const longErrorMsg = "Error is to long to be shown in this toast.";
export const longErrorLink = "Click here to read the whole error..";
